.root {
  .ant-descriptions {
    .ant-descriptions-header {
      .ant-descriptions-title {
        color: @text-color;
      }
    }
    .ant-descriptions-view {
      .ant-descriptions-row {
        th.ant-descriptions-item {
          padding-bottom: 8px;
        }
        td.ant-descriptions-item {
          padding-bottom: 20px;
        }
        .ant-descriptions-item-label {
          color: @text-color-secondary;
        }
        .ant-descriptions-item-content {
          color: @text-color;
        }
      }
    }
  }
}
