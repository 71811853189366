// 自定义字体
@font-face {
  font-family: 'D-DIN-Bold';
  src: url('@{CDN_URL}assets/font/D-DIN-Bold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'D-DIN';
  src: url('@{CDN_URL}assets/font/D-DIN.otf');
  font-display: swap;
}

@font-face {
  font-family: 'HelloFont WenYiHei';
  src: url('@{CDN_URL}assets/font/HelloFont-WenYiHei.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'DingTalk JinBuTi';
  src: url('@{CDN_URL}assets/font/DingTalk-JinBuTi.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('@{CDN_URL}assets/font/Roboto-Bold.ttf');
  font-display: swap;
}

.base-font-size(@font) {
  font-family: @font, -apple-system, 'Arial', sans-serif;
}

.D-DIN {
  .base-font-size('D-DIN');
}

.D-DIN-Bold {
  .base-font-size('D-DIN-Bold');
}

.HelloFont-WenYiHei {
  .base-font-size('HelloFont WenYiHei');
}

.DingTalk-JinBuTi {
  .base-font-size('DingTalk JinBuTi');
}

.Roboto-Bold {
  .base-font-size('Roboto-Bold');
}

.textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}
