.root {
  .ant-skeleton {
    .ant-skeleton-title {
      border-radius: @border-radius;
      background-color: @disabled-bg;
    }

    .ant-skeleton-paragraph {
      margin-bottom: 0;

      li {
        border-radius: @border-radius;
        background-color: @disabled-bg;

        &::after {
          background: linear-gradient(
            90deg,
            @disabled-bg 25%,
            #ecedf2 37%,
            @disabled-bg 63%
          );
        }
      }
    }
  }
}
