.root {
  .ant-dropdown {
    &-menu {
      padding: 8px;
      border-radius: @border-radius;
      box-shadow: @box-shadow;
    }

    &-menu-item {
      padding: 10px 0 10px 12px;
      line-height: 20px;

      &:hover {
        border-radius: @border-radius;
        background-color: @primary-color-bg;
        color: @primary-color !important;

        .anticon {
          color: @primary-color !important;
        }
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      .anticon {
        color: @text-color-secondary;
      }
    }

    &-menu-item-divider {
      margin: 0;
      margin-bottom: 2px;
      background-color: @border-color;
    }
  }
}
