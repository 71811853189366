@import '~antd/es/style/variable.less';
@import '~@/assets/styles/button.less';
@import '~@/assets/styles/form.less';
@import '~@/assets/styles/layout.less';
@import '~@/assets/styles/list.less';
@import '~@/assets/styles/pagination.less';
@import '~@/assets/styles/dropdown.less';
@import '~@/assets/styles/table.less';
@import '~@/assets/styles/descriptions.less';
@import '~@/assets/styles/tooltip.less';
@import '~@/assets/styles/popover.less';
@import '~@/assets/styles/skeleton.less';
@import '~@/global.less';
@import '~@/assets/styles/commonCls.less';

html,
body,
#root {
  height: 100%;
  line-height: 1.5;
  color: @text-color;
  min-width: 1280px;

  // 解决 ResizeObserver loop 报错
  #webpack-dev-server-client-overlay {
    display: none !important;
  }
}

.colorWeak {
  filter: invert(80%);
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

.userForm {
  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-form-item-label > label {
    color: @text-color-secondary;
  }

  .ant-form-item-explain {
    height: 20px !important;
    line-height: 20px !important;
  }
}

.custom-message {
  .ant-message-notice-content {
    height: 48px;
    padding: 12px 24px;
    box-shadow: none;
    border-radius: @border-radius-lg;
    border: 1px solid;
  }

  .anticon {
    font-size: 20px;
  }

  .ant-message-custom-content {
    display: inline-flex;
  }
}

.success-message {
  .ant-message-notice-content {
    border-color: @success-color;
    background-color: @success-color-2;
  }
}

.error-message {
  .ant-message-notice-content {
    border-color: @error-color;
    background-color: @error-color-2;
  }
}

.warning-message {
  .ant-message-notice-content {
    border-color: @warning-color;
    background-color: @warning-color-2;
  }
}

/** 较为复杂的通知 */
.custom-complex-message {
  .ant-message-notice-content {
    width: 400px;
    height: 100%;
    padding: 12px 24px;
    box-shadow: none;
    border-radius: @border-radius-lg;
    border: 1px solid;
  }

  /** 将message自带的icon进行隐藏 */
  .anticon {
    display: none;
  }

  .complex-container {
    .title {
      margin-bottom: 4px;

      /** 这里是我们自定义写的icon */
      .anticon {
        display: block;
        height: 20px;

        .fz-20;
      }
    }

    .description {
      display: block;
      margin-left: 28px;
      text-align: left;
    }
  }
}

.complex-error-message {
  .error-message;

  .anticon {
    color: @error-color;
  }
}

.complex-success-message {
  .success-message;

  .anticon {
    color: @success-color;
  }
}

.complex-warning-message {
  .warning-message;

  .anticon {
    color: @warning-color;
  }
}

@media (max-width: @screen-xs) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;
        > span {
          display: block;
        }
      }
    }
  }
}

.h-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
}

.fx {
  display: flex !important;
}

.fx-h-c:extend(.fx) {
  justify-content: center !important;
}

.fx-v-c:extend(.fx) {
  align-items: center !important;
}

.fx-v-e:extend(.fx) {
  align-items: flex-end !important;
}

.fx-h-r-v-c:extend(.fx) {
  justify-content: flex-end;
  align-items: center;
}

.fx-h-v-c:extend(.fx) {
  justify-content: center !important;
  align-items: center !important;
}

.fx-h-b:extend(.fx) {
  justify-content: space-between !important;
}

.fx-h-b-v-c:extend(.fx) {
  justify-content: space-between !important;
  align-items: center !important;
}

.fx-dir:extend(.fx) {
  flex-direction: column !important;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-24 {
  font-size: 24px !important;
}

.fz-26 {
  font-size: 26px !important;
}

.fz-30 {
  font-size: 30px !important;
}

.fz-32 {
  font-size: 32px !important;
}

.fz-36 {
  font-size: 36px !important;
}

.fz-40 {
  font-size: 40px !important;
}

.fz-44 {
  font-size: 44px !important;
}

.c-p {
  cursor: pointer !important;
}

.f-w-5 {
  font-weight: 500 !important;
}

.f-w-6 {
  font-weight: 600 !important;
}

.c-w {
  color: #fff !important;
}

.il-fx {
  display: inline-flex !important;
}

.flex-1 {
  flex: 1;
}

// 官网 内容区域
.about-content {
  width: 1200px;
  margin: auto;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}
