.root {
  input {
    caret-color: @primary-color !important;

    &::-webkit-input-placeholder {
      color: @text-color-secondary !important;
    }

    &:-ms-input-placeholder {
      color: @text-color-secondary !important;
    }
    &:-moz-placeholder {
      color: @text-color-secondary !important;
    }

    &:-internal-autofill-selected {
      background-color: transparent !important;
      -webkit-text-fill-color: #000 !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }
  }

  /** 文本框 */
  textarea.ant-input {
    caret-color: @primary-color;

    &::placeholder {
      color: @text-color-secondary;
    }
  }

  /** 正常输入框 */
  .ant-input {
    line-height: 18px;
    padding: 6px 8px;
    border-color: @border-color;
    border-radius: @border-radius;
    color: @text-color;

    &[disabled] {
      background-color: @disabled-bg;
      color: @disabled-color;
      border-color: @disabled-border;

      &:hover,
      &:focus {
        background-color: @disabled-bg;
        color: @disabled-color;
        border-color: @disabled-border;
      }
    }

    &:hover {
      border-color: @primary-color;
    }

    &:focus {
      border-color: @primary-color;
      box-shadow: none;
    }

    // 带清除图标的 input
    &-affix-wrapper {
      padding: 4px 8px;
      border-radius: @border-radius;
      border-color: @border-color;
      color: @text-color;

      &:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: @primary-color;
      }

      &:focus,
      &-focused {
        border-color: @primary-color;
        box-shadow: none;
      }

      & > .ant-input {
        border-radius: 0;
      }
    }

    // 大尺寸的 input
    &-lg {
      padding: 10px 8px;
      font-size: 14px;
    }

    // 大尺寸的 password input
    &-affix-wrapper-lg {
      padding: 10px 8px;
      font-size: 14px;
      line-height: 1;

      & > input.ant-input {
        padding: 0;
        border: none;
        outline: none;
      }
    }
  }

  .ant-select {
    color: @text-color;

    /** large模式下字体调小 */
    &.ant-select-lg {
      font-size: 14px;
    }

    // 单选
    &:not(.ant-select-customize-input) .ant-select-selector {
      border-color: @border-color;
      border-radius: @border-radius;
    }

    &-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 8px;
    }

    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: @primary-color;
    }

    &-single .ant-select-selector .ant-select-selection-search {
      left: 8px;
    }

    .ant-select-selection-placeholder {
      color: @text-color-secondary;
    }

    &-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: @primary-color;
      box-shadow: none;
    }

    // 多选
    &-multiple {
      .ant-select-selector {
        padding-left: 8px;
      }

      .ant-select-selection-search {
        margin-inline-start: 0;
      }

      .ant-select-selection-placeholder {
        left: 8px;
      }

      .ant-select-selection-item {
        background-color: @primary-color-bg;
        border: none;
        border-radius: @border-radius;
      }

      .ant-select-selection-item-remove > * {
        line-height: 0;
      }
    }

    // 下拉菜单
    &-dropdown {
      padding: 8px;
      border-radius: @border-radius;
      box-shadow: @box-shadow;
    }

    &-item {
      min-height: 40px;
      line-height: 20px;
      padding: 10px 8px;
      border-radius: 6px;

      &-option-active:not(.ant-select-item-option-disabled) {
        background-color: @primary-color-bg;
      }

      &-option-selected:not(.ant-select-item-option-disabled) {
        font-weight: normal;
        color: @primary-color;
      }
    }
  }

  .ant-picker {
    border-color: @border-color;
    border-radius: @border-radius;

    &:hover,
    &-focused {
      border-color: @primary-color;
      box-shadow: none;
    }

    // &-cell &-cell-inner {
    //   border-radius: @border-radius;
    // }

    // &-cell-in-view&-cell-today &-cell-inner {
    //   background-color: @primary-color-bg;

    //   &::before {
    //     border: none;
    //     border-radius: 0;
    //   }
    // }

    // &-cell:hover:not(&-cell-in-view) &-cell-inner,
    // &-cell:hover:not(&-cell-selected):not(&-cell-range-start):not(&-cell-range-end):not(&-cell-range-hover-start):not(&-cell-range-hover-end)
    //   &-cell-inner {
    //   background-color: @primary-color-bg;
    // }

    // 下拉
    &-panel-container {
      border-radius: @border-radius;
      box-shadow: @box-shadow;
    }
  }

  .ant-input-number {
    border-color: @border-color;
    border-radius: @border-radius;
    color: @text-color;

    /** 带图案的inputNumber框 */
    &-affix-wrapper {
      border-radius: @border-radius;
      color: @text-color;

      &-disabled {
        background-color: @disabled-bg;
        color: @disabled-color;
        border-color: @disabled-border;

        &:hover,
        &:focus {
          background-color: @disabled-bg !important;
          color: @disabled-color !important;
          border-color: @disabled-border !important;
        }
      }

      &:hover {
        border-color: @primary-color;
      }
      &:focus {
        border-color: @primary-color;
        box-shadow: none;
      }
      &-focused {
        box-shadow: none;
      }
    }

    &:hover {
      border-color: @primary-color;
    }
    &:focus {
      border-color: @primary-color;
      box-shadow: none;
    }

    /** focus */
    &:focus {
      box-shadow: none;
    }

    &-focused {
      box-shadow: none;
    }
  }

  .ant-form {
    /** label文本的通用颜色 */
    .ant-form-item-label > label {
      color: @text-color-secondary;
      font-family: PingFangSC-Regular;
    }
  }
}
