.root {
  .ant-popover {
    .ant-popover-content {
      .ant-popover-inner {
        border-radius: @border-radius;
        box-shadow: 0px 2px 12px rgba(47, 58, 102, 0.16);

        .ant-popover-inner-content {
          padding: 16px;
        }
      }
    }
  }
}
