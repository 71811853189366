.root {
  /** 表头 */
  .ant-table-thead > tr > th {
    color: @text-color-4;
    font-size: 14px;
    background-color: #fff;
    padding: 14px 8px;
    border-bottom: 0;
    font-weight: 400;

    &::before {
      width: 0 !important;
    }

    .ant-table-column-sorter {
      .ant-table-column-sorter-inner {
        .anticon {
          /** 排序图标默认颜色 */
          color: @text-color-4;
        }
        /** 排序图标选中的颜色 */
        .active {
          color: @primary-color !important;
        }
      }
    }
  }

  /** 表内容 */
  .ant-table-tbody > tr > td {
    border-bottom: 0;
    padding: 14px 8px;
    font-weight: 500;
    color: @text-color;
    background-color: #fff;
  }

  .ant-table-content {
    overflow: auto hidden !important;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: @disabled-color;
    }
  }
}
