.aside {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 100;
  padding-top: 64px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  :global(.ant-layout-sider-children) {
    position: relative;
    padding: 16px;
  }

  .menu {
    margin-top: -4px;
    border-right: none;
    color: @text-color-2;
    :global {
      /** 每个菜单组都设置灰色边框 */
      .ant-menu-submenu,
      .ant-menu-sub,
      .ant-menu-item-only-child {
        background-color: @disabled-bg !important;
        border-radius: 10px;
      }

      .ant-menu-submenu.ant-menu-submenu-inline {
        .ant-menu-item.ant-menu-item-only-child {
          padding-left: 46px !important;
        }
      }

      /** 上、下菜单组之间 margin 为 8px */
      .ant-menu-submenu {
        margin-bottom: 8px;
      }

      /** 只有一级菜单鼠标悬浮样式 */
      .ant-menu-item-active {
        background-color: @primary-color-bg !important;

        .ant-pro-menu-item {
          .anticon {
            color: @primary-color !important;
          }
        }
      }

      /** 含有二级菜单的一级菜单选中样式 */
      .ant-menu-submenu-active {
        .ant-pro-menu-item {
          .anticon {
            color: @primary-color !important;
          }
        }
      }

      /** 只有一级菜单鼠标选中样式 */
      .ant-menu-item-selected {
        background-color: @primary-color !important;
        color: #fff !important;
        .ant-pro-menu-item {
          color: #fff !important;
          .anticon {
            color: #fff !important;
          }
        }
      }

      .ant-menu-title-content {
        .ant-pro-menu-item {
          display: flex;
          align-items: center;

          /** 默认状态下图标的基本样式 */
          .anticon {
            color: @text-color-4;
            font-size: 20px;
            line-height: 0;
          }
        }
      }

      /** 选中状态下 图标的颜色 */
      .ant-menu-submenu-selected {
        .ant-pro-menu-item {
          .anticon {
            color: @primary-color;
          }
        }
      }

      /** 菜单选中时，图标颜色为白色 */
      .ant-menu-item-selected {
        .ant-pro-menu-item {
          .anticon svg {
            color: #fff;
          }
        }
      }

      /** 当前展开的菜单 标题字体加粗 */
      .ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu-submenu-title {
        font-weight: 500;
      }

      .ant-menu-item,
      .ant-menu-submenu-title {
        width: 100% !important;
      }
    }
  }

  .menu-collspsed {
    :global {
      .ant-menu-title-content {
        .ant-pro-menu-item {
          // 在收起的时候，由于我们的图标是 width=20，比 antd 预留的 width=16 要大，所以向左调整 2px，使其居中
          position: relative;
          left: -2px;
        }
      }
    }
  }

  .menu:global(:not(.ant-menu-inline-collapsed)) {
    :global {
      .ant-menu-item-selected {
        border-radius: @border-radius;
        background-color: @primary-color-bg;
        font-weight: 500;
        &::after {
          content: none !important;
        }
      }

      .ant-menu-submenu-selected {
        /** 选中的菜单字体加粗 */
        .ant-menu-submenu-title {
          font-weight: 500;
        }
        .ant-menu-submenu-arrow {
          color: @primary-color;
        }
      }

      .ant-menu-sub.ant-menu-inline {
        background-color: #fff;
        border-radius: @border-radius;
      }

      .ant-menu-submenu-title {
        border-radius: @border-radius;
      }

      .ant-menu-item {
        border-radius: @border-radius;

        a {
          color: @text-color-2;

          &:hover {
            color: @primary-color;
          }
        }
      }
    }
  }

  .menu:global(.ant-menu-inline-collapsed) {
    :global {
      .ant-menu-item,
      .ant-menu-submenu-title {
        border-radius: @border-radius;
        margin-bottom: 0;
      }

      .ant-menu-item-selected,
      .ant-menu-submenu-selected .ant-menu-submenu-title {
        background-color: @primary-color-bg;
      }

      .ant-menu-item-selected {
        &::after {
          content: none !important;
        }
      }
    }
  }

  .menuFooter {
    display: inline-flex;
    align-items: center;
    line-height: 20px;
    background-color: @disabled-bg;
    border-radius: @border-radius;
    color: @text-color-2;
    cursor: pointer;
    transition: all 0.3s;

    &Fold {
      padding: 10px;
    }

    &Expand {
      width: 70%;
      padding: 10px 12px;
    }

    &:hover {
      color: @primary-color;
      background-color: @primary-color-bg;
    }

    .action {
      margin-left: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
    }

    :global(.anticon) {
      padding: 3px;
    }
  }
}

/** 控制popup模式下的menu样式 */
.custom-menu-root {
  left: 70px !important;

  :global {
    .ant-menu-vertical {
      padding: 8px 12px;
      box-shadow: 0px 2px 12px rgba(47, 58, 102, 0.1);
      border-radius: @border-radius;

      .ant-menu-item {
        min-width: 152px;
        border-radius: @border-radius;

        &:hover {
          background-color: @primary-color-bg;
        }
      }

      .ant-menu-item-selected {
        border-radius: @border-radius;
        font-weight: 500;
      }
    }
  }
}

.menu-sider-content {
  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
  }
}

@root-entry-name: default;@CDN_URL: "https://bluevs-static.domobcdn.com/";@primary-color: #334eff;@primary-color-hover: #5d72ff;@primary-color-active: #293ecb;@primary-color-bg: #ebefff;@text-color: #151421;@text-color-2: #484a54;@text-color-secondary: #797c8a;@text-color-4: #979ba8;@main-bg: #f5f7fa;@border-radius: 8px;@border-radius-lg: 12px;@border-color: #e1e3eb;@disabled-color: #c5c8d1;@disabled-bg: #f7f9fc;@disabled-border: @border-color;@success-color: #28ce8e;@success-color-2: #e4fcf3;@error-color: #ff5233;@error-color-2: #ffeae6;@error-color-3: #EBEFFF;@warning-color: #f5b300;@warning-color-2: #fff5d8;@warning-color-3: #ff851c;@warning-color-4: #ffedd8;@box-shadow: 0px 2px 12px rgba(47, 58, 102, 0.1);@downward-box-shadow: 0 18px 24px -20px rgba(0,0,0,0.08);@upward-box-shadow: 0px -16px 24px -18px rgba(0,0,0,0.08);