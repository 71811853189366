.root {
  .ant-btn {
    padding: 10px 24px;
    height: 40px;
    line-height: 20px;
    border: none;
    border-radius: @border-radius;
  }

  .ant-btn-lg {
    padding: 14px 20px;
    height: 48px;
  }

  .ant-btn-primary {
    font-weight: 500;
    background-color: @primary-color;
    text-shadow: none;
    box-shadow: none;

    &[disabled] {
      border: 1px solid @disabled-border;
      color: @disabled-color;
      background-color: @disabled-bg;

      &:hover,
      &:focus,
      &:active {
        border: 1px solid @disabled-border;
        color: @disabled-color;
        background-color: @disabled-bg;
      }
    }

    &:hover,
    &:focus {
      background-color: @primary-color-hover;
      border-color: @primary-color-hover;
    }

    &:active {
      background-color: @primary-color-active;
      border-color: @primary-color-active;
    }
  }

  .ant-btn-default {
    border: 1px solid #e8e8e9;
    color: #323233;

    &[disabled] {
      border-color: @disabled-border;
      color: @disabled-color;
      background-color: @disabled-bg;
      font-weight: 500;

      &:hover,
      &:focus,
      &:active {
        font-weight: 500;
        border-color: @disabled-border;
        color: @disabled-color;
        background-color: @disabled-bg;
      }
    }

    &:hover,
    &:focus {
      border-color: @primary-color-hover;
      color: @primary-color-hover;
    }

    &:active {
      border-color: @primary-color-active;
      color: @primary-color-active;
    }
  }

  .ant-btn-sm {
    padding: 6px 16px;
    height: 32px;
  }

  .ant-btn-text {
    color: @primary-color;
    font-weight: 500;
    padding: 0;
    // line-height: 1;
    height: auto;

    &[disabled] {
      color: @disabled-color;

      &:hover,
      &:focus,
      &:active {
        color: @disabled-color;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;
      color: @primary-color-hover;
    }

    &:active {
      background-color: @disabled-bg;
      color: @primary-color-active;
    }
  }

  /** primary类型下 dangerous按钮 */
  .ant-btn-dangerous.ant-btn-primary {
    color: #fff;
    background-color: @error-color;
    border-color: @error-color;

    &:hover,
    &:focus {
      background-color: #ff7875;
      border-color: #ff7875;
    }

    &:active {
      background-color: #d9363e;
      border-color: #d9363e;
    }
  }

  .ant-btn-link {
    color: @primary-color;
    font-weight: 500;
    padding: 0;
    height: auto;
  }
}
