.root {
  .ant-list {
    margin-top: 6px;
    border-top: 1px solid @border-color;

    .ant-list-header {
      color: @text-color-4;
    }

    .ant-spin-nested-loading {
      .ant-spin-container.ant-spin-blur {
        & > div {
          min-height: 166px !important;
        }
      }
    }

    .ant-list-pagination {
      margin-top: 16px;
    }
  }
}
