.close {
  color: #00000073;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s;

  &:hover {
    color: #000000bf;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.only-close {
  display: flex;
  justify-content: end;
}

@root-entry-name: default;@CDN_URL: "https://bluevs-static.domobcdn.com/";@primary-color: #334eff;@primary-color-hover: #5d72ff;@primary-color-active: #293ecb;@primary-color-bg: #ebefff;@text-color: #151421;@text-color-2: #484a54;@text-color-secondary: #797c8a;@text-color-4: #979ba8;@main-bg: #f5f7fa;@border-radius: 8px;@border-radius-lg: 12px;@border-color: #e1e3eb;@disabled-color: #c5c8d1;@disabled-bg: #f7f9fc;@disabled-border: @border-color;@success-color: #28ce8e;@success-color-2: #e4fcf3;@error-color: #ff5233;@error-color-2: #ffeae6;@error-color-3: #EBEFFF;@warning-color: #f5b300;@warning-color-2: #fff5d8;@warning-color-3: #ff851c;@warning-color-4: #ffedd8;@box-shadow: 0px 2px 12px rgba(47, 58, 102, 0.1);@downward-box-shadow: 0 18px 24px -20px rgba(0,0,0,0.08);@upward-box-shadow: 0px -16px 24px -18px rgba(0,0,0,0.08);