.root {
  .ant-pagination {
    display: flex;
    justify-items: flex-end;
    width: 100%;
    .ant-pagination-total-text {
      flex: 1;
      text-align: left;
      .desc {
        color: @text-color-secondary;
        .total {
          color: @text-color;
        }
      }
    }
    .ant-pagination-item-link {
      border-radius: @border-radius;
    }
    .ant-pagination-item {
      border-radius: @border-radius;
    }
    .ant-pagination-item-active {
      background-color: @primary-color;
      a {
        color: #fff;
      }
    }
    .ant-pagination-options-quick-jumper input {
      border-radius: @border-radius;
    }
  }
}
