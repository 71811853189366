.root {
  .ant-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
          box-shadow: none;

          &::before {
            clip-path: inset(33% 33%);
            box-shadow: none;
          }
        }
      }
      .ant-tooltip-inner {
        padding: 12px;
        border-radius: @border-radius;
        box-shadow: none;
      }
    }
  }
}
