/** 资产监控Tooltip */
.assetTooltip {
  .mask {
    margin-right: 8px;

    width: 40px;
    height: 40px;
    background-color: @error-color-2;
    border-radius: @border-radius;

    .icon {
      color: @error-color;
    }
  }

  .desc {
    margin-right: 30px;
    color: @text-color;
  }

  .total {
    color: @error-color;
    font-family: D-DIN-Bold;
  }
}

.right {
  line-height: 1;

  .operate {
    height: 100%;

    /** 资产监控 */
    .assetMonitor {
      padding: 0 24px;
      color: #fff;
    }

    /** 资产监控Tag */
    .assetTag {
      margin-left: 7px;

      .mask {
        position: relative;
        padding: 4px 5px;
        background-color: @error-color;
        border-radius: 4px;
        font-family: D-DIN-Bold;

        .total {
          position: inherit;
          z-index: 999;
        }

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          background-color: @error-color;
          position: absolute;
          top: 50%;
          left: -2px;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }

    .seaSchool {
      color: #fff;
    }

    .partner {
      padding-right: 24px;
    }
    .online {
      margin-right: 24px;
      height: inherit;
    }

    .helpCenter {
      padding: 0 24px;
      height: inherit;
    }
  }

  .user {
    height: 100%;

    :global(.ant-avatar) {
      background-color: @primary-color;
    }

    .info {
      margin-left: 8px;
      color: #fff;
    }

    .rest {
      margin-top: 6px;
      color: @disabled-color;

      .divider {
        display: inline-block;
        width: 1px;
        height: 12px;
        margin: 0 4px;
        background-color: @text-color-4;
      }
    }
  }
}

.menu {
  :global(.anticon) {
    margin-right: 8px;
    font-size: 20px;
  }

  :global(.ant-dropdown-menu-item) {
    height: 40px;
    min-width: 120px;
    padding-right: 10px !important;
  }
}

.userInfo {
  :global {
    .ant-form-item {
      &:first-child {
        label {
          width: 100%;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.modifyPassowrd {
  .tips {
    margin-bottom: 20px;
    color: @text-color-secondary;
  }

  .confirm {
    width: 100%;
    margin-top: 12px;
  }

  .success {
    text-align: center;

    :global {
      .anticon {
        margin-bottom: 20px;
        font-size: 72px;
        color: @success-color;
      }
    }

    .title {
      margin-bottom: 12px;
      font-weight: 600;
    }

    .desc {
      margin-bottom: 32px;
      color: @text-color-secondary;
    }
  }
}

.confirmModal {
  .header {
    :global {
      .anticon {
        margin-right: 6px;
        padding: 4px;
        border-radius: @border-radius;
      }
    }
  }

  .desc {
    margin-bottom: 32px;
    color: @text-color-2;
  }

  .btns {
    text-align: right;

    button {
      &:last-child {
        margin-left: 20px;
      }
    }
  }
}

.logoutIcon {
  background-color: @error-color-2;
  color: @error-color;
}

.switchSmbIcon {
  background-color: @primary-color-bg;
  color: @primary-color;
}

.identification {
  position: relative;
  display: flex;
  align-items: center;
  .text {
    margin-right: 8px;
  }
  .icon {
    position: absolute;
    left: 64px;
  }
}

/** boxStyle */
.boxWrap {
  display: flex;
  line-height: 100%;
  justify-content: center;
  align-items: center;
  .bg {
    margin-right: 24px;
    background: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }

  &:hover {
    & > .base {
      display: block;
    }
  }
  .base {
    position: absolute;
    top: 50px;
    display: none;
    width: max-content;
    background: #ffffff;
    border: 1px solid #dadcdd;
    border-radius: 4px;
    transition: all 0.3s;
    padding: 24px;
    white-space: nowrap;
  }

  .box {
    width: 260px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(21, 20, 33, 0.1);
    border-radius: 16px;

    .boxTitle {
      text-wrap: wrap;
      min-height: 31px;
      font-weight: 400;
      font-size: 19px;
      color: #151421;
      line-height: 31px;

      .boxTitleIcon {
        width: 18px;
        height: 18px;
      }

      .boxTitleText {
        margin-left: 9px;
      }
    }

    .boxDesc {
      margin-top: 8px;
      min-width: 156px;
      text-wrap: wrap;
      min-height: 17px;
      font-family: 'PingFang SC';
      font-weight: 400;
      font-size: 12px;
      color: #797c8a;
      line-height: 17px;
    }

    .boxContent {
      margin-top: 20px;
      font-weight: normal;
      font-size:28px;
      color: #334eff;

      .boxContentImg {
        width: 212px;
        height: 212px;
        background: #ffffff;
        border-radius: 0px;
      }
    }

    .lineContent {
      margin-top: 20px;
      width: 194px;
      height: 35px;
      font-family: D-DIN-Bold;
      font-weight: normal;
      font-size: 32px;
      color: #334eff;
      line-height: 35px;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}


.sms-code {
  background: #EBEFFF;
  border-radius: 0 0 8px 8px;
  padding: 8px 12px 12px;
  position: absolute;
  top: -34px;
}

.saveBtnNo {
  display: none;
}

.username {
  width: 100%;
}

@root-entry-name: default;@CDN_URL: "https://bluevs-static.domobcdn.com/";@primary-color: #334eff;@primary-color-hover: #5d72ff;@primary-color-active: #293ecb;@primary-color-bg: #ebefff;@text-color: #151421;@text-color-2: #484a54;@text-color-secondary: #797c8a;@text-color-4: #979ba8;@main-bg: #f5f7fa;@border-radius: 8px;@border-radius-lg: 12px;@border-color: #e1e3eb;@disabled-color: #c5c8d1;@disabled-bg: #f7f9fc;@disabled-border: @border-color;@success-color: #28ce8e;@success-color-2: #e4fcf3;@error-color: #ff5233;@error-color-2: #ffeae6;@error-color-3: #EBEFFF;@warning-color: #f5b300;@warning-color-2: #fff5d8;@warning-color-3: #ff851c;@warning-color-4: #ffedd8;@box-shadow: 0px 2px 12px rgba(47, 58, 102, 0.1);@downward-box-shadow: 0 18px 24px -20px rgba(0,0,0,0.08);@upward-box-shadow: 0px -16px 24px -18px rgba(0,0,0,0.08);