.root {
  .ant-pro-global-header-layout-mix {
    padding: 0 24px !important;
    background-color: #12153a;
  }

  .ant-layout {
    background-color: @main-bg;
  }

  .ant-layout-has-sider {
    flex-direction: row;
    position: relative;
  }

  .ant-pro-global-header-collapsed-button {
    display: none;
  }
}
