.close-btn {
  background: #EBEFFF;
  border-radius: 8px;
  color: #334EFF;
  font-weight: 500;
  font-size: 16px;
  padding: 13px 0;
  border: none;
  flex: 1;
  cursor: pointer;
}

.prev-btn,
.next-btn {
  background: #334EFF;
  box-shadow: 0px 2px 12px rgba(41,62,203,0.24);
  border-radius: 8px;
  border: none;
  color: #FFF;
  font-weight: 500;
  font-size: 16px;
  padding: 13px 0;
  flex: 1;
  cursor: pointer;
}

.tooltip-title {
  font-size: 24px;
  color: #151421;
  font-weight: 600;
}

.tour-tooltip {
  position: relative;

  &-left::before,
  &-right::before,
  &-right-bottom::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: solid 12px transparent;
    border-bottom: solid 12px transparent;
  }
  &-left::before {
    top: 24px;
    left: -16px;
    border-right: solid 16px #fff;
  }
  &-right::before {
    top: 24px;
    right: -16px;
    border-left: solid 16px #fff;
  }
  &-right-bottom::before {
    right: -16px;
    bottom: 24px;
    border-left: solid 16px #fff;
  }

  &-top::before,
  &-bottom::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: solid 12px transparent;
    border-right: solid 12px transparent;
  }
  &-bottom::before {
    bottom: -16px;
    border-top: solid 16px #fff;
  }
  &-top::before {
    top: -16px;
    border-bottom: solid 16px #fff;
  }
}

.count {
  white-space: nowrap;
}

@root-entry-name: default;@CDN_URL: "https://bluevs-static.domobcdn.com/";@primary-color: #334eff;@primary-color-hover: #5d72ff;@primary-color-active: #293ecb;@primary-color-bg: #ebefff;@text-color: #151421;@text-color-2: #484a54;@text-color-secondary: #797c8a;@text-color-4: #979ba8;@main-bg: #f5f7fa;@border-radius: 8px;@border-radius-lg: 12px;@border-color: #e1e3eb;@disabled-color: #c5c8d1;@disabled-bg: #f7f9fc;@disabled-border: @border-color;@success-color: #28ce8e;@success-color-2: #e4fcf3;@error-color: #ff5233;@error-color-2: #ffeae6;@error-color-3: #EBEFFF;@warning-color: #f5b300;@warning-color-2: #fff5d8;@warning-color-3: #ff851c;@warning-color-4: #ffedd8;@box-shadow: 0px 2px 12px rgba(47, 58, 102, 0.1);@downward-box-shadow: 0 18px 24px -20px rgba(0,0,0,0.08);@upward-box-shadow: 0px -16px 24px -18px rgba(0,0,0,0.08);