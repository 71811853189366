@width: 424px;

.form {
  width: @width;
}

h1 {
  font-weight: 600;
  color: @text-color;
}

.btn {
  width: 100%;
  height: 44px;
  margin-top: 12px;
  margin-bottom: 16px;
}

.card {
  width: 100%;
  margin-bottom: 20px;
  padding: 0 16px;
  background-color: @disabled-bg;
  border-radius: @border-radius;
  list-style: none;

  li {
    padding: 12px 0;

    &:not(:last-child) {
      border-bottom: 1px solid @border-color;
    }

    span:first-child {
      color: @text-color-2;
    }
  }
}

.checkbox {
  margin-top: 4px;
  margin-bottom: 20px;
}

// 登录
.login {
  h1 {
    margin-bottom: 24px;
  }
}

// 激活
.active {
  h1 {
    margin-bottom: 24px;
  }
}

// 注册
.register {
  h1 {
    margin-bottom: 24px;
  }
}

// 忘记密码
.forget {
  h1 {
    margin-bottom: 8px;
  }

  .tips {
    margin-bottom: 24px;
    color: @text-color-secondary;
  }
}

// 重置密码
.reset {
  h1 {
    margin-bottom: 24px;
  }
}

.submitSuccess {
  width: 100%;
  text-align: center;

  .icon {
    color: @success-color;
    font-size: 72px;
  }

  .title {
    margin: 16px 0 12px;
    font-weight: 600;
  }

  .desc {
    margin-bottom: 32px;
    color: @text-color-secondary;
  }
}

.alert {
  background-color: @error-color-2;
  border-color: @error-color;
  border-radius: @border-radius;
}

.register-source {
  margin-bottom: 12px;
}

.remember {
  :global {
    .ant-space-item {
      display: flex;
    }
  }
}

@root-entry-name: default;@CDN_URL: "https://bluevs-static.domobcdn.com/";@primary-color: #334eff;@primary-color-hover: #5d72ff;@primary-color-active: #293ecb;@primary-color-bg: #ebefff;@text-color: #151421;@text-color-2: #484a54;@text-color-secondary: #797c8a;@text-color-4: #979ba8;@main-bg: #f5f7fa;@border-radius: 8px;@border-radius-lg: 12px;@border-color: #e1e3eb;@disabled-color: #c5c8d1;@disabled-bg: #f7f9fc;@disabled-border: @border-color;@success-color: #28ce8e;@success-color-2: #e4fcf3;@error-color: #ff5233;@error-color-2: #ffeae6;@error-color-3: #EBEFFF;@warning-color: #f5b300;@warning-color-2: #fff5d8;@warning-color-3: #ff851c;@warning-color-4: #ffedd8;@box-shadow: 0px 2px 12px rgba(47, 58, 102, 0.1);@downward-box-shadow: 0 18px 24px -20px rgba(0,0,0,0.08);@upward-box-shadow: 0px -16px 24px -18px rgba(0,0,0,0.08);